@font-face{
    font-family: 'JetBrains Mono';
    src: url('https://raw.githubusercontent.com/JetBrains/JetBrainsMono/master/fonts/web/JetBrainsMono-Regular.eot') format('embedded-opentype'),
         url('https://raw.githubusercontent.com/JetBrains/JetBrainsMono/master/fonts/web/JetBrainsMono-Regular.woff2') format('woff2'),
         url('https://raw.githubusercontent.com/JetBrains/JetBrainsMono/master/fonts/web/JetBrainsMono-Regular.woff') format('woff'),
         url('https://raw.githubusercontent.com/JetBrains/JetBrainsMono/master/fonts/ttf/JetBrainsMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.App {
  text-align: center;
}

.bg {
  height: 40vmin;
  /* pointer-events: none; */
}


.header {
  /* background-color: #282c34; */
  background-color: rgba(0,0,0,0);
  background-image: linear-gradient(240deg, rgb(21, 7, 80) 10.36%, rgb(132, 0, 64) 94.28%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'JetBrains Mono';
  font-size: calc(16px + 2vmin);
  color: white;
  font-feature-settings: "liga" on, "calt" on;
  -moz-font-feature-settings: "liga" on, "calt" on;
  -webkit-font-feature-settings: "liga" on, "calt" on;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.typewriter p {
  overflow: hidden;
  border-right: .40em solid white;
  white-space: nowrap;
  margin: 0 auto;
  animation:
    typing 1.5s steps(20, end) 0.5s 1 normal both,
    blink-caret 1s step-end infinite;
}

@keyframes typing {
  from {width: 0}
  to { width: 100%}
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

.contact {
  color: rgba(255,255,255);
  font-size: calc(10px + 0.5vmin);
  margin-top: 1.5em;
  opacity: 0;
  animation: showContact 1.5s ease-in 2s forwards;
  animation-fill-mode: forwards;
}

@keyframes showContact {
  to { opacity: 1;}
}